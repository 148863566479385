import { dateTimeFormat, GrafanaTheme2 } from '@grafana/data';
import { CustomSeriesOption } from 'echarts';
// import { EChartsOption, CustomSeriesOption } from 'echarts';

// let prevIndex = 0;
// let counter = 0;
export class ActivitySeriesView {
  theme: GrafanaTheme2;
  palette: string[];
  fontSize: number;
  width: number | string;
  constructor(theme: GrafanaTheme2, width: number | string, fontSize = 16) {
    this.theme = theme;
    this.width = width;
    this.fontSize = fontSize;
    this.palette = [
      theme.colors.text.primary,
      '#5470c6',
      '#91cc75',
      '#fac858',
      '#ee6666',
      '#73c0de',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc',
      'Brown',
      'MidnightBlue',
      'Cyan',
      'Green',
      'Red',
      'Purple',
      'DeepPink',
      'Yellow',
      'Blue',
      'Orange',
    ];
  }
  activityDict: string[] = [];
  getColorPalette(activityName: string) {
    if (this.activityDict.indexOf(activityName) < 0) {
      this.activityDict.push(activityName);
    }
    let palette = this.palette;
    return palette[this.activityDict.indexOf(activityName) % (palette.length - 1)];
  }
  renderActivityItem: CustomSeriesOption['renderItem'] = (params, api) => {
    let currentIndex = api.value!(0);
    let activity = api.value!(1);
    let width = 0;
    if (typeof this.width === 'string' && this.width.endsWith('%')) {
      //calculate width of the track
      width = (api.getWidth() * Number.parseFloat(this.width.replace('%', '').trim())) / 100;
      //deduct 5 pixels so the last character is not cut-out
      width = width - 5;
    }

    let datetime = dateTimeFormat(currentIndex, { format: 'MM-DD HH:mm:ss' });
    return {
      type: 'text',
      position: api.coord!([0, currentIndex]),
      style: {
        // text: `{a|${datetime + '  '}}{b|${activity}}`,
        text: `${datetime + '  '}${activity}`,
        fill: this.getColorPalette(activity.valueOf().toString()),
        fontSize: this.fontSize,
        width,
        overflow: 'break',
        // rich: {
        //   a: {
        //     fontSize: this.fontSize - 8,
        //   },
        //   b: {
        //     fontSize: this.fontSize,
        //   },
        // },
      },
    };
  };
}
