import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { PanelProps, LoadingState, PanelData, TimeRange, GrafanaTheme2 } from '@grafana/data';

import { PageToolbar, ToolbarButton, VerticalGroup, Input, Modal, ConfirmModal, withTheme2 } from '@grafana/ui';
import ReactECharts from 'echarts-for-react';
import { DataZoomComponentOption, ECharts } from 'echarts';

import { LogPlotOptions } from './types';
// import WellLogPlotEchartsOptions from 'utils';
// import MultiWellLogplotOptions from 'MultiWellLogplotOptions';
import MultiWellLogplotWithMarker from './MultiWellLogplotWithMarker';
import { debounce } from 'lodash';
import FormationMarkerTable from './components/FormationMarkerTable';
import MultiWellLogplotOptions from './MultiWellLogplotOptions';
import registerIdleEventHandler from './components/idleTime';
import { getTemplateSrv } from '@grafana/runtime';
// registerIdleEventHandler();

interface Props extends PanelProps<LogPlotOptions> {
  theme: GrafanaTheme2;
}

const PartialLogPlotPanel: React.FC<Props> = ({
  options,
  data,
  width,
  height,
  theme,
  timeRange,
  fieldConfig,
  ...others
}) => {
  const logplotOptions = useMemo<MultiWellLogplotOptions>(
    () =>
      data.request!.targets.length === 1 ? new MultiWellLogplotOptions(theme) : new MultiWellLogplotWithMarker(theme),
    [data.request!.targets.length]
  );
  const isMultiWell = data.request!.targets.length > 1;

  //state
  const [loading, setLoading] = useState<boolean>(true);
  const [noMerge, setNoMerge] = useState<boolean>(false);
  const [echartInst, setEchartInst] = useState<ECharts | undefined>(undefined);
  const [linkYAxis, setLinkYAxis] = useState(true);
  const [isMarkerEditorOpen, setIsMarkerEditorOpen] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  const [lastZoomValues, setLastZoomValues] = useState<Array<{ id: string; startValue: number; endValue: number }>>([]);

  //toolbox
  // const [isToolboxOpen, setIsToolboxOpen] = useState(false);
  const [goToIndexVal, setGoToIndexVal] = useState<string>('');
  const [showHandleIdle, setShowHandleIdle] = useState(false);
  const [showHandleIdleSeconds, setShowHandleIdleSeconds] = useState(10);
  const [clearTimeoutHandle, setClearTimeoutHandle] = useState<any>();

  //#region feature: scroll to bottom if idle

  useEffect(() => {
    if (!echartInst) {
      return;
    }
    const scrollToBottom = () => {
      let counter = 10;
      setShowHandleIdleSeconds(counter);

      let currentOption = echartInst?.getOption();
      let currentDataZooms = (currentOption as any).dataZoom as any[];
      let dz: any = currentDataZooms.find((z) => z.type === 'slider');

      //already at the bottom
      if (dz.end > 99) {
        return;
      }

      setShowHandleIdle(true);

      let hd = setInterval(() => {
        setShowHandleIdleSeconds(counter);
        counter--;
        if (counter <= 0) {
          clearTimeout(hd);
          setShowHandleIdle(false);
          //now scroll to bottom
          let delta = dz.end - dz.start;
          let update = { start: 100 - delta, end: 100 };
          echartInst?.dispatchAction(
            {
              type: 'dataZoom',
              dataZoomId: dz.id,
              ...update,
            },
            { silent: true }
          );
        }
      }, 1000);
      setClearTimeoutHandle(hd);
    };

    let idleScrollTime = 60000 * 3; //3 minutes
    let v = getTemplateSrv()
      .getVariables()
      .find((v) => v.name === 'scrollOnIdleTime');
    if (v) {
      idleScrollTime = Number.parseFloat(getTemplateSrv().replace('$scrollOnIdleTime')) * 1000;
    }
    if (idleScrollTime === 0 || isMultiWell) {
      //idle scroll is disabled. or its multi well
      return;
    }

    let cleanup = registerIdleEventHandler(() => scrollToBottom(), idleScrollTime);
    return () => {
      // alert('runing cleanup');
      cleanup();
    };
  }, [echartInst, isMultiWell, setClearTimeoutHandle]);

  //#endregion

  const resetOption = debounce(() => {
    if (echartInst === undefined) {
      return;
    }
    let depthRange = getDepthRange(data, timeRange);

    const o = logplotOptions.prepareOption(options, data, depthRange.range, depthRange.isDepth, echartInst!);
    logplotOptions.setData(data);

    setLastZoomValuesFromArray(o.dataZoom as DataZoomComponentOption[]);
    setNoMerge(false);
  }, 300);

  const setLastZoomValuesFromArray = (lastDataZoom: DataZoomComponentOption[]) => {
    let lastZoomSimple = lastDataZoom.map((z) => {
      return { id: z.id as string, startValue: z.startValue!, endValue: z.endValue! };
    });
    setLastZoomValues(lastZoomSimple);
  };

  const setOptionData = debounce(
    () => {
      // setLoading(true);
      console.info('calling setdata================');

      if (data.state === LoadingState.Streaming && !loading) {
        // console.log('data.state streaming================');
        setLoading(true);
        //do nothing
      }
      logplotOptions.setData(data);
      if (data.state === LoadingState.Done || (data.series.length > 0 && data.series[0].fields[0].values.length > 0)) {
        // console.log('data.state done================');
        setLoading(false);
      }
    },
    300,
    { leading: true }
  );

  useEffect(() => {
    if (echartInst && data.series.length > 0) {
      console.log('prepareOption================');
      resetOption();
      console.info('data.series[0].fields[0].values.length', data.series[0].fields[0].values.length);
      console.info('data', data);
    }

    if (data.series.length > 0 && data.series[0].fields[0].values.length > 0) {
      setOptionData();
    }
    //TODO: make sure data.series.length does not change from Datasource!!, to prevent re-rendering
  }, [echartInst, options, fieldConfig, timeRange.raw, data.series.length]);
  // }, [echartInst, data.series.length]);
  // });

  useEffect(() => {
    echartInst && setOptionData();
  }, [echartInst, data]);
  // }, [data.series]);

  const onLinkYAxisChange = useCallback(
    (el: any) => {
      setLinkYAxis(!linkYAxis);
    },
    [linkYAxis, echartInst]
  );

  const openMarkerEditor = useCallback(() => {
    // alert('not implemented');
    setIsMarkerEditorOpen(!isMarkerEditorOpen);
    if (isMarkerEditorOpen) {
    }
  }, [isMarkerEditorOpen]);

  // const saveMarkers = useCallback(() => {
  //   alert('save not implemented');
  //   // setIsMarkerEditorOpen(!isMarkerEditorOpen);
  //   // if (isMarkerEditorOpen) {
  //   // }
  // }, [isMarkerEditorOpen]);

  function onGoToIndex() {
    if (!echartInst) {
      return;
    }
    (echartInst.getOption().dataZoom as any[])
      // .filter((dz) => dz.type === 'slider')
      .forEach((dz, i) => {
        if (dz.type !== 'slider') {
          return;
        }
        // let dz = (echartInst.getOption().dataZoom as any[])[0];
        // console.log(dz);
        let startValue = Number.parseFloat(goToIndexVal);
        let endValue = startValue - dz.startValue + dz.endValue;
        echartInst?.dispatchAction(
          {
            type: 'dataZoom',
            // dataZoomId: dz.id,
            dataZoomIndex: i,
            startValue,
            endValue,
          },
          { silent: true }
        );
      });
    logplotOptions.resize();
  }

  //link axis if multi-well
  useEffect(() => {
    if (!echartInst || !isMultiWell) {
      return;
    }

    //link the two axis
    //TODO: to be moved to MultiWellLogOptions.ts
    let handler = (e: any) => {
      let currentDataZooms = (echartInst?.getOption() as any).dataZoom;
      if (linkYAxis) {
        // console.log(e);
        let theDataZoom: any = currentDataZooms.find(
          (dz: any) => dz.id === (e.batch ? e.batch[0].dataZoomId : e.dataZoomId)
        )!;
        if (theDataZoom === undefined) {
          //this is an recursive call!, cancel update
          return;
        }

        // console.log(lastZoomValues);

        let lastDz = lastZoomValues.find(
          (dz: any) => dz.id === theDataZoom.id.replace('dataZoomYInSider', 'dataZoomYSlider')
        )!;

        let update = currentDataZooms
          .filter(
            (dz: any) => dz.id.split('_')[1] !== theDataZoom.id.split('_')[1] && dz.id.indexOf('dataZoomYInSider') < 0
          )
          .map((otherDz: any) => {
            let theOtherLastVal = lastZoomValues.find((z) => z.id === otherDz.id)!;

            return {
              id: otherDz.id,
              // assume zoom is not supported!!!
              startValue: theDataZoom.startValue - lastDz.startValue + theOtherLastVal.startValue,
              endValue: theDataZoom.endValue - lastDz.endValue + theOtherLastVal.endValue,
            };
          });

        let updateYMinMax = logplotOptions.setYMinMax(
          Math.min(...update.map((u: any) => u.startValue)),
          Math.max(...update.map((u: any) => u.endValue))
        );
        if (updateYMinMax) {
          console.log({ update, updateYMinMax });
          echartInst?.setOption(updateYMinMax);
        }

        let batch = update.map((u: any) => {
          return {
            dataZoomIndex: currentDataZooms.findIndex((z: any) => z.id === u.id),
            startValue: u.startValue,
            endValue: u.endValue,
          };
        });
        echartInst?.dispatchAction({
          type: 'dataZoom',
          batch,
        });
      } else {
        setLastZoomValuesFromArray(currentDataZooms);
      }
    };

    echartInst?.on('dataZoom', handler);

    return () => {
      echartInst?.off('dataZoom', handler);
    };
  }, [echartInst, lastZoomValues, linkYAxis, isMultiWell]);

  //resize
  useEffect(() => {
    logplotOptions?.resize();
  }, [width, height, logplotOptions]);

  function onChartReady(instance: any) {
    if (instance === null) {
      throw 'echart instance is null!';
    }
    console.log('onchart ready');
    (window as any)['echartInst'] = instance;
    setEchartInst(instance);
  }

  // function on

  return (
    <VerticalGroup>
      <ConfirmModal
        isOpen={showHandleIdle}
        title={'idle'}
        body={`showing latest data in ${showHandleIdleSeconds} seconds`}
        confirmText={'Dismiss'}
        onConfirm={() => {
          setShowHandleIdle(false);
          console.log('clearing:' + clearTimeoutHandle);

          clearInterval(clearTimeoutHandle);
        }}
        // dismissText="Dismiss"
        onDismiss={() => {
          setShowHandleIdle(false);
          console.log('clearing:' + clearTimeoutHandle);
          clearInterval(clearTimeoutHandle);
        }}
      />
      {isMultiWell && (
        <Modal
          title="Formation Markers"
          isOpen={isMarkerEditorOpen && isMultiWell}
          onDismiss={() => openMarkerEditor()}
        >
          <FormationMarkerTable
            logplotOptionWithMarker={logplotOptions as MultiWellLogplotWithMarker}
          ></FormationMarkerTable>
        </Modal>
      )}

      {isMultiWell && (
        <PageToolbar
          pageIcon="square-shape"
          title="Toolbox"
          leftItems={[
            <ToolbarButton
              key="btnLink"
              icon="link"
              onClick={() => onLinkYAxisChange(!linkYAxis)}
              // onChange={onLinkYAxisChange}
              tooltip="Unlock Individual Scollbar"
              variant={linkYAxis ? 'active' : 'default'}
            ></ToolbarButton>,
            <ToolbarButton
              key="btnShow"
              icon={showMarkers ? 'eye' : 'eye-slash'}
              onClick={() => {
                setShowMarkers(!showMarkers);
                (logplotOptions as MultiWellLogplotWithMarker).showOrHideMarkerGraphics(!showMarkers);
              }}
              // onChange={onLinkYAxisChange}
              tooltip={showMarkers ? 'Hide Markers' : 'Show Markers'}
              variant={showMarkers ? 'active' : 'default'}
            ></ToolbarButton>,

            <ToolbarButton
              key="btnTable"
              icon="table"
              onClick={() => openMarkerEditor()}
              // onChange={onLinkYAxisChange}
              tooltip="Edit Formation Markers"
              variant={isMarkerEditorOpen ? 'active' : 'default'}
            ></ToolbarButton>,
            <ToolbarButton
              key="btnSave"
              icon="save"
              onClick={() => (logplotOptions as MultiWellLogplotWithMarker).saveFormations()}
              tooltip="Save Formation Marker Changes"
            ></ToolbarButton>,
            <Input
              key="iptGoToIndex"
              // placeholder="1000"
              type="number"
              value={goToIndexVal}
              onChange={(el) => setGoToIndexVal(el.currentTarget.value)}
              onKeyDown={(k) => (k.key === 'Enter' ? onGoToIndex() : '')}
              addonBefore={<div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>Go To Index</div>}
            />,
            // </InlineField>,
          ]}
        ></PageToolbar>
      )}
      <ReactECharts
        option={{}}
        theme={theme.isDark ? 'dark' : undefined}
        // opts={{ renderer: 'canvas', useDirtyRect: true } as any}
        // lazyUpdate={true}
        notMerge={noMerge}
        style={{
          // width,
          // height,
          width: options.horizontal ? height : width,
          height: options.horizontal ? width : height,
          transformOrigin: 'top left',
          transform: options.horizontal ? 'rotate(-90deg) translate(-105%)' : '',
        }}
        // style={size}
        onChartReady={onChartReady}
        showLoading={loading}
      />
    </VerticalGroup>
  );
};

interface EtpQueryCustomMeta {
  wellName: string;
  etpUrl: string;
  dataObjectType: 'Log' | 'MudLog' | 'Trajectory';
  indexType: 'time' | 'depth';
  isDepthIndex: boolean;
  from?: number;
  to?: number;
}

function getDepthRange(data: PanelData, timeRange: TimeRange) {
  let depth = data.series.find((f) => f.meta?.custom?.isDepthIndex) !== undefined;

  if (depth) {
    let wells = [...new Set(data.series.map((s) => s.name!))];
    let range = wells.map((w) => {
      let fromTos = data.series
        .filter((s) => s.name === w && s.meta?.custom?.dataObjectType === 'Log')
        .map((s) => {
          let customMeta = s.meta?.custom as EtpQueryCustomMeta;
          // customMeta.dataObjectType =
          console.log(customMeta);
          return { wellName: w, from: customMeta.from!, to: customMeta.to! };
        })
        .reduce((prev, current) => {
          return {
            wellName: prev.wellName,
            from: Math.min(prev.from, current.from),
            to: Math.max(prev.to, current.to),
          };
        });

      return fromTos;
    });

    return { isDepth: true, range };
  } else {
    let from = timeRange.from.unix() * 1000;
    let to = timeRange.to.unix() * 1000;

    let wellName: string = data.series[0].name!;
    return { isDepth: false, range: [{ from, to, wellName }] };
  }
}

export const LogPlotPanel = withTheme2(PartialLogPlotPanel);
