/* eslint-disable */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTable, CellProps, Column } from 'react-table';
import { Input, HorizontalGroup, Button, IconButton } from '@grafana/ui';
import MultiWellLogplotWithMarker, { Formation, Marker } from '../MultiWellLogplotWithMarker';

// interface Formation {
//   name: string;
//   // depth: string;
//   // markers?: Marker[];
//   [others: string]: any;
// }

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

// Create an editable cell renderer
const EditableCell: React.FC<
  CellProps<Formation> & { updateMyData: (update: { rowId: number; colId: string; newVal: Marker }) => void }
> = ({
  value,
  row,
  column,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally

  if (column.id === 'name') {
    let color = Object.values(row.original.markers)[0]?.color;
    return <b style={{ color }}>{value}</b>;
  }
  const [newVal, setNewVal] = React.useState<Marker>(value);
  // console.log(value);

  const onChange = (e: any) => {
    // setNewVal({ val: e.target.value, isDirty: true });
    setNewVal({
      ...newVal,
      formationDepth: Number.parseFloat(e.target.value),
      isDirty: true,
      formationName: row.original.name,
    });
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData({ rowId: row.index, colId: column.id, newVal });
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setNewVal(value);
  }, [value]);

  return (
    <Input
      style={{ color: newVal?.isDirty ? 'brown' : '' }}
      type="number"
      value={Number.isNaN(newVal?.formationDepth) ? '' : newVal?.formationDepth}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

const DeletableCell: React.FC<CellProps<Formation> & { deleteMyData: (formation: string) => void }> = ({
  value,
  row,
  column,
  deleteMyData, // This is a custom function that we supplied to our table instance
}) => {
  // return <div>❌</div>;
  return (
    <IconButton
      name="minus-circle"
      tooltip="Delete Formation"
      color="red"
      style={{ color: 'red' }}
      onClick={() => deleteMyData(row.original.name)}
    />
  );
  // return <Icon name="minus-circle" color="red" />;
};

interface Props {
  // wellView: WellViewWithMarker;
  // updateData: (rowId: number, colId: string, newVal: string) => void;
  // data?: { formations: Formation[]; wells: string[] };
  logplotOptionWithMarker: MultiWellLogplotWithMarker;
}
const FormationMarkerTable: React.FC<Props> = (prop) => {
  const [newFormationName, setNewFormationName] = useState('');

  const [data, setData] = useState(prop.logplotOptionWithMarker.getFormations());
  if (data === undefined) return null;

  const [saveCounter, setSaveCounter] = useState(1);
  useEffect(() => {
    setData(prop.logplotOptionWithMarker.getFormations());
  }, [saveCounter]);

  // const [newData, setNewData] = useState(prop.data);

  const columns = React.useMemo<Array<Column<Formation>>>(() => {
    let cols: Array<Column<Formation>> = [
      {
        Header: 'Formation',
        // Cell: DeletableCell,
        accessor: 'name',
      },
      // {
      //   Header: 'Depth',
      //   accessor:(originalRow,rowIndex)=>{
      //     originalRow.markers
      //   }
      // },
    ];

    data.wells.map((w) => {
      cols.push({
        Header: w,
        accessor: (originalRow, rowIndex) => {
          return originalRow.markers[w];
        },
      });
    });

    cols.push({
      Header: ' ',
      Cell: DeletableCell as any,
      // accessor: 'action',
    });
    return cols;
  }, [saveCounter]);

  let tableOptions = {
    columns,
    data: data.formations,
    defaultColumn: { Cell: EditableCell },
    // initialState: { updateMyData: console.log },
    // updateMyData: console.log,
    updateMyData: (update: { rowId: number; colId: string; newVal: Marker }) => {
      prop.logplotOptionWithMarker.updateFormations(update);
    },
    deleteMyData: async (formation: string) => {
      await prop.logplotOptionWithMarker.deleteFormation(formation);
      setSaveCounter((v) => v + 1);
    },
  };
  const { getTableProps, getTableBodyProps, headers, prepareRow, rows } = useTable<Formation>(tableOptions as any);

  return (
    <Styles>
      <HorizontalGroup>
        <Input
          placeholder="formation 1"
          value={newFormationName}
          onChange={(el) => setNewFormationName(el.currentTarget.value)}
          onKeyDown={(k) => k.key === 'Enter' && prop.logplotOptionWithMarker.addFormation(newFormationName)}
          addonBefore={<div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>New Formation Name</div>}
          addonAfter={
            <Button
              icon="plus"
              onClick={() => {
                prop.logplotOptionWithMarker.addFormation(newFormationName);
                setSaveCounter((v) => v + 1);
              }}
            >
              Add
            </Button>
          }
        />
        <Button
          icon="save"
          onClick={() => {
            prop.logplotOptionWithMarker.saveFormations();
            setSaveCounter((v) => v + 1);
          }}
        >
          Save
        </Button>
      </HorizontalGroup>

      <p></p>
      <table {...getTableProps()}>
        <thead>
          <tr>
            {headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};
export default FormationMarkerTable;
/* eslint-enable */
