// import { EChartOption } from 'echarts';
// import * as echarts from 'echarts';
import { GraphicComponentOption, graphic } from 'echarts';

export function renderGeologyIntervals(params: any, api: any, codeLith: string) {
  // console.profile('renderGeologyIntervals');
  // return undefined;

  //this method is called so frequently, it has to be optimized
  // console.info('====renderGeologyIntervals===');
  var currentIndex = api.value!(0);
  var geoIntv = api.value!(1);
  let mdTop = geoIntv.mdTop;
  let mdBottom = geoIntv.mdBottom;
  let lith = geoIntv.lith;
  // let l = lith.find((li: any) => li.codeLith === codeLith);
  let l = lith.filter((li: any) => li.codeLith === codeLith);

  if (!l || l.length === 0) {
    return undefined;
  }
  // let txt = lith.map((l: any) => `${l.codeLith} : ${l.lithPc}%`).join(' ,');
  var start = api.coord!([0, currentIndex]);
  var size = api.size!([10, mdBottom - mdTop]);
  if (l.length === 1) {
    return renderRect(params, api, codeLith, l[0], 0, lith, start, size);
    // return;
  } else {
    //there are multiple matched entries, wrap them into a group
    let g: GraphicComponentOption = {
      children: l.map((l2: any, idx: number) => renderRect(params, api, codeLith, l2, idx, lith, start, size, 3666)),
      type: 'group',
      z: 3666,
      // shape: {
      //   start: start[0] + 3,
      //   y: start[1],
      // },
    };
    return g;
  }
}

function renderRect(
  params: any,
  api: any,
  codeLith: string,
  l: any,
  idx: number,
  lith: any,
  start: any,
  size: any,
  z = 5000
) {
  let lastStart = start[0] + 3;
  let foundCount = 0;
  for (let i = 0; i < lith.length; i++) {
    l = lith[i];
    if (l.codeLith === codeLith) {
      if (foundCount === idx) {
        break;
      } else {
        foundCount++;
      }
    }

    let lithSize = (size[0] - 5) * (l.lithPc / 100);
    lastStart += lithSize;
  }

  let lithSize = (size[0] - 5) * (l.lithPc / 100);

  let rectShape = graphic.clipRectByRect(
    {
      x: lastStart,
      y: start[1],
      width: lithSize,
      height: size[1],
    },
    {
      x: params.coordSys!.x!,
      y: params.coordSys!.y!,
      width: params.coordSys!.width!,
      height: params.coordSys!.height!,
    }
  );

  return (
    rectShape && {
      type: 'rect',
      shape: rectShape,
      style: api.style!({
        lineWidth: 0,
      }),
      z,
    }
  );
}
