import { PanelPlugin, SetFieldConfigOptionsArgs } from '@grafana/data';
import { GraphFieldConfig } from '@grafana/ui';
import { LogPlotPanel } from './LogPlotPanel';
// import { graphPanelChangedHandler } from './migrations';
import { LogPlotOptions } from './types';
import { defaultGraphConfig, getGraphFieldConfig } from './copiedFromAlpha75/timeSeriesConfig';
//extend GraphFieldConfig
export interface LogPlotFieldConfig extends GraphFieldConfig {
  trackId: number;
  order: number;
  fontSize: number;
  nullAsZero: boolean;
}
export const defaultLogPlotFieldConfig: LogPlotFieldConfig = {
  ...defaultGraphConfig,
  trackId: 0,
  order: 0,
  fontSize: 16,
  nullAsZero: false,
};

function getLogPlotFieldConfig(): SetFieldConfigOptionsArgs<LogPlotFieldConfig> {
  let fc = getGraphFieldConfig(defaultLogPlotFieldConfig);
  let oldUseCustomConf = fc.useCustomConfig!;
  fc.useCustomConfig = (builder) => {
    oldUseCustomConf(builder);
    builder.addNumberInput({
      path: 'trackId',
      name: 'Track Number',
      defaultValue: defaultLogPlotFieldConfig.trackId,
    });
    builder.addNumberInput({
      path: 'order',
      name: 'Order',
      defaultValue: defaultLogPlotFieldConfig.order,
    });
    builder.addNumberInput({
      path: 'fontSize',
      name: 'Font Size',
      defaultValue: defaultLogPlotFieldConfig.fontSize,
    });
    builder.addBooleanSwitch({
      path: 'nullAsZero',
      name: 'Null As Zero',
      defaultValue: defaultLogPlotFieldConfig.nullAsZero,
    });
  };
  return fc;
}

// export type LogPlotFieldConfig = GraphFieldConfig & TrackOption;

export const plugin = new PanelPlugin<LogPlotOptions, LogPlotFieldConfig>(LogPlotPanel)
  .useFieldConfig(getLogPlotFieldConfig())
  .setPanelOptions((builder) => {
    builder
      .addRadio({
        path: 'tooltipOptions.mode',
        name: 'Tooltip mode',
        description: '',
        defaultValue: 'single',
        settings: {
          options: [
            { value: 'single', label: 'Single' },
            { value: 'multi', label: 'All' },
            { value: 'none', label: 'Hidden' },
          ],
        },
      })
      .addBooleanSwitch({
        path: 'depthLog',
        name: 'Depth Log',
        description: 'is this depth log',
        defaultValue: false,
      })
      .addNumberInput({
        path: 'tracks',
        name: 'Tracks',
        description: 'Number of Tracks',
        defaultValue: 1,
      })
      .addNumberInput({
        path: 'hideGridlinesOnTrack',
        name: 'Hide Gridlines on Track',
        // description: 'Hide Gridlines on Track',
        defaultValue: -1,
      })
      .addBooleanSwitch({
        path: 'individualScrollBar',
        name: 'Show Individual ScrollBar',
        // description: 'Hide Gridlines on Track',
        defaultValue: true,
      })
      .addBooleanSwitch({
        path: 'horizontal',
        name: 'Horizontal',
        // description: 'Hide Gridlines on Track',
        defaultValue: false,
      })
      .addTextInput({
        path:'axisratio',
        name: 'Axis Ratio',
        defaultValue:'${axisratio},${axisratio_time},${axisratio_depth}'
      })

    //addLegendOptions(builder);
  });
