// let counter = 0;
export default function registerIdleEventHandler(handler: () => void, timeout = 5000) {
  var t: any;

  function registerEvents() {
    window.addEventListener('load', resetTimer);
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('touchstart', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('keydown', resetTimer);

    window.addEventListener('scroll', resetTimer, true); // improved; see comments
  }

  function unregisterEvents() {
    window.removeEventListener('load', resetTimer);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('mousedown', resetTimer);
    window.removeEventListener('touchstart', resetTimer);
    window.removeEventListener('click', resetTimer);
    window.removeEventListener('keydown', resetTimer);

    window.removeEventListener('scroll', resetTimer); // improved; see comments
    clearTimeout(t);
  }
  registerEvents();

  function resetTimer() {
    // document.title = 'reset timer' + (counter++).toString();
    clearTimeout(t);
    t = setInterval(handler, timeout); // time is in milliseconds
  }
  return () => unregisterEvents();
}
//  registerIdleEventHandler();
