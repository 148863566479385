// @ts-nocheck
import { EChartsOption, GraphicComponentOption, ECharts, GridComponentOption, YAXisComponentOption } from 'echarts';

import MultiWellLogplotOptions, { Range, WellView } from './MultiWellLogplotOptions';
import { LogPlotOptions } from 'types';
import { PanelData } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { debounce } from 'lodash';
export interface Marker {
  formationName: string;
  id: string;
  lineId: string;
  formationDepth: number;
  well: string;
  firstGridInWell: GridComponentOption;
  isDirty: boolean;
  toBeDeleted: boolean;
  color: string;
}

export interface Formation {
  name: string;
  // depth: string;
  // markers?: Marker[];
  markers: { [well: string]: Marker | undefined };
}

export interface WellViewWithMarker extends WellView {
  formationMarkers?: Marker[];
}
const MARKER_LINE_STYLE = {
  // text: formationName,
  fontSize: 16,
  // fill: 'red',
  // stroke: 'red',
  lineWidth: 2,
  // shadowBlur: 8,
  // shadowOffsetX: 2,
  // shadowOffsetY: 2,
  // shadowColor: 'rgba(0,0,0,0.2)',
};
export default class MultiWellLogplotWithMarker extends MultiWellLogplotOptions {
  protected gridGraphicElement: GraphicComponentOption[] = [];
  wells!: { [key: string]: WellViewWithMarker };

  /**
   *
   */
  constructor(theme: GrafanaTheme) {
    super(theme);
  }

  prepareOption(
    logPlotOptions: LogPlotOptions,
    data: PanelData,
    indexRange: Range[],
    isDepth: boolean,
    echartInst: ECharts
  ): EChartsOption {
    this.axisCoordMinMax = undefined;
    let option = super.prepareOption(logPlotOptions, data, indexRange, isDepth, echartInst);

    // console.log("this.echartInst.on('dataZoom', this.updateMarkers)");
    // console.log(this.echartInst.on);
    const up = debounce(() => this.updateGraphicElements(), 1);
    this.echartInst.on('dataZoom', up);
    // this.echartInst.on('dataZoom', () => this.updateGraphicElements());
    // this.echartInst.on('resize', () => this.updateGraphicElements());
    window.addEventListener('resize', () => this.resize());
    return option;
  }
  resize() {
    // console.log('resized');
    super.resize();
    this.getYAxisCoordMinMax(true);
    this.updateGraphicElements();
  }

  private axisCoordMinMax: { coordMin: number; coordMax: number } | undefined;
  private getYAxisCoordMinMax(refresh = false) {
    if (this.axisCoordMinMax !== undefined && !refresh) {
      return this.axisCoordMinMax;
    } else {
      if (!this.echartInst) {
        return;
      }
      let o = this.echartInst.getOption();
      let y = (o as any).yAxis[0];
      let z = (o as any).dataZoom[0];
      // console.log(z);

      let coordMin = this.echartInst.convertToPixel({ yAxisIndex: 0 }, z.startValue!);
      // let coordMin = this.headerGridHeight;
      let coordMax = this.echartInst.convertToPixel({ yAxisIndex: 0 }, z.endValue!);
      this.axisCoordMinMax = { coordMin, coordMax };
      // console.log(this.axisCoordMinMax);
      return this.axisCoordMinMax;
    }
  }

  private createNewFormationMarker(
    w: WellView,
    formationName: string,
    formationDepth: number,
    isDirty = false
  ): Marker {
    let id = `graphic_group_${w.wellName}_${formationName}`;
    let lineId = `graphic_line_${w.wellName}_${formationName}`;
    let firstGridInWell = this.grid[w.tracks[0].indexInGrid];

    let marker: Marker = {
      well: w.wellName,
      id,
      formationDepth,
      formationName,
      firstGridInWell,
      lineId,
      isDirty,
      color: this.getColorPalette2(formationName),
    };
    return marker;
  }

  protected updateMarkers() {
    // console.log('calling  updateMarkers111');
    // console.log(this);

    if (
      this.data === undefined ||
      this.echartInst === undefined ||
      this.echartInst === null ||
      this.echartInst.isDisposed()
    ) {
      return;
    }
    // console.log('calling  updateMarkers');

    this.gridGraphicElement = [];
    let markerSeries = this.data.series.filter((s) => s.fields.find((f) => f.name === 'formationMarker'));
    this.gridGraphicElement = [];

    //load markers
    if (markerSeries.length > 0 && Object.keys(this.wells).length > 0) {
      markerSeries.map((s) => {
        let w = this.wells[s.name!] as WellViewWithMarker;
        if (w === undefined) {
          return;
        }
        // let firstGridInWell = this.grid[w.tracks[0].indexInGrid];

        let formationMarkerField = s.fields.find((f) => f.name === 'formationMarker')!;
        let depthField = s.fields[0];
        if (w.formationMarkers === undefined) {
          w.formationMarkers = [];
        }
        for (let index = 0; index < depthField.values.length; index++) {
          let formationDepth = depthField.values.get(index);
          let formationName = formationMarkerField.values.get(index);
          // let id = `graphic_group_${w.wellName}_${formationName}`;
          // let lineId = `graphic_line_${w.wellName}_${formationName}`;

          // let marker: Marker = {
          //   well: w.wellName,
          //   id,
          //   formationDepth,
          //   formationName,
          //   firstGridInWell,
          //   lineId,
          //   isDirty: false,
          //   color: this.getColorPalette2(formationName),
          // };
          let marker = this.createNewFormationMarker(w, formationName, formationDepth);

          //add marker only if it does not exist yet!
          if (w.formationMarkers.find((m) => m.id === marker.id) === undefined) {
            w.formationMarkers.push(marker);
          }
        }
      });
    }

    this.updateGraphicElements();
  }

  showOrHideMarkerGraphics(showOrHide: boolean) {
    if (this.showMarkerGraphics !== showOrHide) {
      this.showMarkerGraphics = showOrHide;
      this.updateGraphicElements();
    }
  }
  protected showMarkerGraphics = true;

  // protected updateGraphicElements() {
  //   let d = debounce(() => this.updateGraphicElements(), 1);
  //   d();
  // }
  protected updateGraphicElements() {
    // return;
    let containsMudLog = this.data?.series.find((s) => s.meta?.custom['dataObjectType'] === 'MudLog') !== undefined;

    // if (showMarkerGraphics !== undefined) {
    //   this.showMarkerGraphics = showMarkerGraphics;
    // }
    if (
      this.data === undefined ||
      this.echartInst === undefined ||
      this.echartInst === null ||
      this.grid.length === 0 ||
      this.echartInst.isDisposed()
    ) {
      return;
    }
    this.gridGraphicElement = [];
    let chartWidth = this.echartInst.getWidth();
    // let containerTopBottom = this.getYAxisCoordMinMax();
    let containerTopBottom = this.getYAxisCoordMinMax(true);

    let markerGraphic = Object.values(this.wells).map((w: WellViewWithMarker) => {
      return w.formationMarkers!.map((m) => {
        let formationDepthCoord = this.echartInst.convertToPixel({ gridId: m.firstGridInWell.id }, [
          0,
          m.formationDepth,
        ]);
        if (Number.isNaN(m.formationDepth) || formationDepthCoord === undefined) {
          return undefined;
        }

        let isInvisible =
          formationDepthCoord[1] > containerTopBottom.coordMax || formationDepthCoord[1] < containerTopBottom.coordMin;
        isInvisible = isInvisible || !this.showMarkerGraphics;
        isInvisible = isInvisible || m.toBeDeleted;
        let g: GraphicComponentOption = {
          type: 'group',
          id: m.id,
          $action: isInvisible ? 'remove' : 'merge',
          left: w.gridsLeft + '%',
          top: formationDepthCoord[1],
          draggable: 'vertical',
          ondrag: (param: any) => this.onMarkerDrag(param, m),
          ondragend: (param: any) => this.onMarkerDragEnd(param, m),

          z: 6000,
          zlevel: 103,
          children: [
            {
              type: 'line',
              $action: isInvisible ? 'remove' : 'merge',
              id: m.lineId,
              // $action: 'replace',
              shape: {
                x1: 0,
                x2: (chartWidth * w.gridsWidth) / 100,
                // y1: 500,
                // y2: 500,
              },
              textContent: {
                style: {
                  text: m.formationName + ': ' + m.formationDepth + 'm',
                  fontSize: 16,
                  fill: m.color,
                  stroke: m.color,
                },
              },
              textConfig: {
                position: containsMudLog ? 'insideBottom' : 'insideBottomRight',
              },
              style: {
                ...MARKER_LINE_STYLE,
                fill: m.color,
                stroke: m.color,
              },
              z: 6000,
            },
          ],
        };

        return g;
      });
    });

    //connection lines
    let connectionLines: GraphicComponentOption[] = [];

    Object.values(this.wells).forEach((currenWell, currentIndex) => {
      if (currentIndex === 0) {
        //skip the first well
        return;
      } else {
        let prevWell = Object.values(this.wells)[currentIndex - 1] as WellViewWithMarker;
        prevWell.formationMarkers?.forEach((prevM) => {
          let currentM = (currenWell as WellViewWithMarker).formationMarkers?.find(
            (m) => m.formationName === prevM.formationName
          );
          if (currentM === undefined) {
            return;
          }

          let depthCoordPrev = this.echartInst.convertToPixel({ gridId: prevM.firstGridInWell.id }, [
            0,
            prevM.formationDepth,
          ]);

          let depthCoordCurrent = this.echartInst.convertToPixel({ gridId: currentM.firstGridInWell.id }, [
            0,
            currentM.formationDepth,
          ]);
          if (depthCoordPrev === undefined || depthCoordCurrent === undefined) {
            return;
          }
          let isInvisible =
            depthCoordPrev[1] > containerTopBottom.coordMax ||
            depthCoordCurrent[1] > containerTopBottom.coordMax ||
            depthCoordCurrent[1] < containerTopBottom.coordMin ||
            depthCoordPrev[1] < containerTopBottom.coordMin;
          isInvisible = isInvisible || !this.showMarkerGraphics;
          isInvisible = isInvisible || prevM.toBeDeleted || currentM.toBeDeleted;
          connectionLines.push({
            type: 'group',
            id: `marker_connector_${prevM.formationName}_${prevWell.wellName}_${currenWell.wellName}`,
            left: prevWell.gridsLeft + prevWell.gridsWidth + '%',
            $action: isInvisible ? 'remove' : 'merge',

            children: [
              {
                type: 'line',
                id: `marker_connector_line_${prevM.formationName}_${prevWell.wellName}_${currenWell.wellName}`,
                $action: isInvisible ? 'remove' : 'merge',

                shape: {
                  // x1: (chartWidth * (prevWell.gridsLeft + prevWell.gridsWidth)) / 100,
                  x1: 0,
                  x2: (chartWidth * (currenWell.gridsLeft - prevWell.gridsWidth - prevWell.gridsLeft)) / 100,
                  y1: depthCoordPrev[1] + 1,
                  y2: depthCoordCurrent[1] + 1,
                },
                style: {
                  ...MARKER_LINE_STYLE,
                  fill: currentM.color,
                  stroke: currentM.color,
                },
              },
            ],
          });
        });
      }
    });

    // console.log(connectionLines);

    // let formationNames = Object.values(this.wells).flatMap((w: WellViewWithMarker) => w.formationMarkers?);
    // .flatMap((m) => m.formationName);
    //remove duplicate
    // formationNames = [...new Set(formationNames)];

    this.gridGraphicElement = markerGraphic.flat();
    this.gridGraphicElement.push(...connectionLines);
    // this.graphic.push(this.gridGraphicElement);
    let option: EChartsOption = {
      // graphic: { elements: [...this.graphic, ...this.gridGraphicElement] },
      // graphic: { elements: this.gridGraphicElement },
      graphic: this.gridGraphicElement,
    };
    this.echartInst?.setOption(option, false, true);
  }

  private onMarkerDrag(param: any, m: Marker) {
    // console.log(param);
    // console.log(this);
    // this.onMarkerDragEnd(param, m);
  }
  private onMarkerDragEnd(param: any, m: Marker) {
    // console.log(param);
    // let yCoord = param.target.y;
    let newDepth = this.echartInst.convertFromPixel({ gridId: m.firstGridInWell.id }, [0, param.target.y])[1];

    m.formationDepth = newDepth;
    m.isDirty = true;
    this.updateGraphicElements();
    this.saveFormations();
    // console.log(m);
    // console.log(param.target.id);
  }

  setData(data: PanelData): EChartsOption {
    if (!this.echartInst) {
      return {};
    }
    let option = super.setData(data, false);

    this.updateMarkers();
    if (this.gridGraphicElement.length > 0) {
      // if (option.graphic === undefined) {
      //   option.graphic = [];
      // }
      let newGraphicElements = [...this.graphic, ...this.gridGraphicElement];
      if (option.graphic !== undefined) {
        newGraphicElements = [...option.graphic, ...newGraphicElements];
        // debugger;
      }
      option.graphic = newGraphicElements;
    }
    // if (option.graphic) debugger;

    this.echartInst?.setOption(option, false, true);
    return option;
  }

  protected initWells(options: LogPlotOptions, data: PanelData, indexRange: Range[]) {
    super.initWells(options, data, indexRange);
    Object.values(this.wells).forEach((w) => (w.formationMarkers = []));
  }

  protected initGrid(options: LogPlotOptions, data: PanelData) {
    super.initGrid(options, data);

    // Object.values(this.wells).map((w) => {

    //   this.gridGraphicElement.push({
    //     type: 'group',
    //     id: `graphic_${w.wellName}`,
    //     left:w.gridsLeft + "%",
    //     width:1000,
    //     elements:[

    //     ]
    //     // top:

    //   });
    // });
  }

  protected initXAxis() {
    super.initXAxis();
    //hide formation marker xAxis
    //TODO: do not add xAxis of formation marker.
    this.xAxis.forEach((x) => {
      if (x.name?.trim() === 'formationMarker') {
        x.axisLine!.show = false;
        x.axisLabel!.show = false;
        x.nameTextStyle!.opacity = 0;
      }
    });
  }

  getFormations() {
    // console.log(Object.values(this.wells));
    let formationNames = [
      ...new Set(
        Object.values(this.wells)
          .flatMap((w) => w.formationMarkers)
          .flatMap((m) => (m ? m.formationName : ''))
          .filter((fn) => fn !== '')
      ),
    ];

    let formations: Formation[] = formationNames.map((fn) => {
      let f: Formation = {
        name: fn,
        markers: {},
      };
      Object.values(this.wells).map((w) => {
        let marker = w.formationMarkers?.find((fm) => fm.formationName === fn);

        // if (marker !== undefined) {
        // f[w.wellName] = marker; ? { val: marker.formationDepth, isDirty: marker.isDirty } : { val: '', isDirty: false };

        f.markers[w.wellName] = marker;
        // }
      });
      return f;
    });

    let result = { wells: Object.keys(this.wells), formations };
    // console.log(result);
    return result;
  }
  updateFormations(update: { rowId: number; colId: string; newVal: Marker }) {
    // console.log(update);
    let fToBeUpdated = this.getFormations().formations[update.rowId];

    let markers = this.wells[update.colId].formationMarkers;

    let marker = markers?.find((f) => f.formationName === fToBeUpdated.name);
    // console.log(fToBeUpdated);
    // console.log(marker);
    if (marker === undefined) {
      console.log('create new marker');
      console.log(update);
      // markers?.push({
      //   well: update.colId,
      //   formationName: fToBeUpdated.name,
      //   formationDepth: Number.parseFloat(update.newVal),
      // });
      if (this.wells[update.colId].formationMarkers === undefined) {
        this.wells[update.colId].formationMarkers = [];
      }
      marker = this.createNewFormationMarker(
        this.wells[update.colId],
        update.newVal.formationName,
        update.newVal.formationDepth,
        true
      );

      this.wells[update.colId].formationMarkers!.push(marker);
    } else {
      marker.isDirty = true;
      marker.formationDepth = update.newVal.formationDepth;
    }

    this.updateGraphicElements();
    this.saveFormations();
  }

  async deleteFormation(formationName: string) {
    // alert('deleting ' + formationName);

    Object.values(this.wells).forEach((w) => {
      let idx = w.formationMarkers!.findIndex((f) => f.formationName === formationName);
      if (idx >= 0) {
        w.formationMarkers[idx].isDirty = true;
        w.formationMarkers[idx].toBeDeleted = true;
      }
    });

    // this.updateGraphicElements();
    await this.saveFormations();
  }

  addFormation(name: string) {
    // alert('add formation ' + name);
    Object.values(this.wells).map((w) => {
      let marker = this.createNewFormationMarker(w, name, Number.NaN, true);
      w.formationMarkers?.push(marker);
    });
    this.updateGraphicElements();
    this.saveFormations();
  }
  async saveFormations() {
    let markers = Object.values(this.wells)
      .flatMap((w) => w.formationMarkers!)
      .filter((m) => m.isDirty);
    markers.forEach((m) => (m.isDirty = false));
    // alert('save not implemented');

    // return;
    // let markers = Object.values(this.wells)
    //   .flatMap((w) => w.formationMarkers!)
    //   .filter((m) => m.isDirty);

    let ds_srv = getDataSourceSrv();

    let d = await ds_srv.get('etp-data-plugin');

    this.updateGraphicElements();

    for (let i = 0; i < markers.length; i++) {
      const m = markers[i];

      if (!Number.isNaN(m.formationDepth)) {
        if (m.toBeDeleted) {
          console.warn('deleting: ' + m.formationName + ' well:' + m.well);

          //remove formation from the well ViewModel
          let idx = this.wells[m.well].formationMarkers!.findIndex((f) => f.formationName === m.formationName);
          this.wells[m.well].formationMarkers?.splice(idx, 1);
          await d.deleteFormationMarker(m.well, m.formationName);
        } else {
          await d.putFormationMarker(m.well, m.formationName, m.formationDepth);
        }
      }
    }
    // this.updateGraphicElements();
    // });
    // let ds = ds_srv.getList({ dashboard: true }).filter((ds) => ds.name === 'etp-data-plugin');
    // console.log(ds);

    // Object.values(this.wells).forEach(w=>{
    //   w.formationMarkers
    // })
  }
}
